body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  user-select: none;
}

div.react-datepicker__day--selected,
div.react-datepicker__day--in-range {
  background-color: #01bca6;
}

div.react-datepicker__day--selected:hover,
div.react-datepicker__day--in-range:hover {
  background-color: #008374;
}

div.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: #01bca6;
}

div.react-datepicker__day--keyboard-selected {
  background-color: #008374;
}

div.react-datepicker__day--keyboard-selected:hover {
  background-color: #00241f;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  width: 22px;
  height: 22px;
}

input[type="time"]::-webkit-calendar-picker-indicator:hover {
  background-color: #ccf4ef;
  cursor: pointer;
}
